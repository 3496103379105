import { useAuth0 } from "@auth0/auth0-react"
import mixpanel from "mixpanel-browser"
import { useEffect } from "react"
import { matchPath, useLocation } from "react-router"
import type { SettingGranularity } from "../components/Settings/notifications/contexts/NotificationContext"
import { useGetAsset } from "../components/WatchedLocations/hooks/useAssetService"
import { useOrganizationId } from "../hooks/useOrganizationId"
import { useSearchParams } from "../hooks/useSearchParams"
import { ROUTES } from "./constants"
import { AUTH0_CLIENT_ID } from "./env"
import { getLocationId, hashData } from "./helpers"

interface AnalyticsNotificationSettings {
  analysisEnabled: boolean
  alertsEnabled: boolean
  /** ```ts
   * AssetNotificationAlertLevel[alertLevel]
   * ```
   */
  alertLevel: string
  riskIncreaseEnabled: boolean
  historicalAbsenteeismEnabled: boolean
  forecastedAbsenteeismEnabled: boolean
  /** ```ts
   * Disease_Type[threat]
   * ```
   */
  selectedThreats: string[]
  expertInsightsSettingGranularity: SettingGranularity
}

type ManageLocationAction =
  | ({
      action: "add" | "edit"
      locationId: string | undefined
      /**```ts
       * IndustryType[industry]
       * ```*/
      industry: string
    } & Partial<AnalyticsNotificationSettings>)
  | { action: "delete"; locationId: string | undefined }

interface AnalyticsEvents {
  // click events
  CLICK_SAVE_PDF: null
  SAVE_NOTIFICATIONS: AnalyticsNotificationSettings
  DISCARD_NOTIFICATIONS: null
  OPEN_DISEASE_FACTOR: {
    /**```ts
     * Disease_Type[disease]
     * ```*/
    disease: string
    locationId: string | undefined
  }
  CLICK_ALERT_SOURCE: {
    title: string
    link: string
  }
  CLICK_ALERT_SEE_MORE: {
    title: string
    link: string
  }
  WORKFORCE_PLANNING_INDUSTRY_CHANGE: {
    /**```ts
     * IndustryType[industry]
     * ```*/
    industry: string
  }
  MANAGE_LOCATION: ManageLocationAction
  OPEN_ASK: null
  QUERY_ASK: {
    query: string[]
  }
  CLICK_ASK_HISTORY: {
    query: string[]
  }
}

const apiKey = import.meta.env.MIXPANEL_TOKEN || "empty token"

mixpanel.init(apiKey, {
  persistence: "localStorage",
  // debug: true,
})

export const useMixpanel = () => {
  const orgId = useOrganizationId()
  const { user } = useAuth0()
  useMixpanelPageView()
  const userId = user?.email
  useEffect(() => {
    const initMixpanel = async () => {
      const hashedUserId = userId
        ? await hashData(AUTH0_CLIENT_ID + userId)
        : undefined
      const hashedOrgId = orgId.data
        ? await hashData(AUTH0_CLIENT_ID + orgId.data)
        : undefined
      hashedUserId && mixpanel.identify(hashedUserId)
      hashedOrgId && mixpanel.set_group("orgId", hashedOrgId)
    }
    initMixpanel().catch(console.error)
  }, [orgId.data, userId])
}

export const useMixpanelPageView = () => {
  const { pathname, search } = useLocation()
  const searchParams = useSearchParams()
  const { isLoading } = useAuth0()
  const assetMatch = matchPath(ROUTES.ASSET, pathname)
  const { data: assetData, isLoading: assetLoading } = useGetAsset(
    assetMatch?.params.assetId || searchParams.params.assetId
  )

  const assetLocationId = getLocationId(assetData?.asset)
  const locationId = assetLocationId || searchParams.params.id
  // needed for the useEffect to trigger on url and query param changes
  const url = pathname + search
  // trying to reduce deps so we don't trigger the effect too much
  const loading = isLoading || assetLoading
  useEffect(() => {
    if (loading || !url) return

    // handle registering locationId for all events on page
    if (!locationId) {
      mixpanel.unregister("locationId", {
        persistent: false,
      })
    } else {
      mixpanel.register(
        {
          locationId: locationId,
        },
        {
          persistent: false,
        }
      )
    }

    // track pageview after registering locationId
    mixpanel.track_pageview()

    return () => {
      // clean up registered properties
      mixpanel.unregister("locationId", {
        persistent: false,
      })
    }
  }, [loading, locationId, url])
}

export const trackEvent = <Event extends keyof AnalyticsEvents>(
  event: Event,
  properties?: AnalyticsEvents[Event]
) => {
  mixpanel.track(event, { ...properties })
}

const getByteLength = (str: string) => new Blob([str]).size
/**
 * Splits a string into chunks small enough
 * for mixpanel custom properties
 *
 * https://docs.mixpanel.com/docs/data-structure/property-reference/data-type#string
 */
export const splitIntoChunks = (str: string, chunkSize = 255) => {
  const chunks = []
  let currentChunk = ""
  let currentChunkSize = 0

  for (const char of str) {
    const charSize = getByteLength(char)
    if (currentChunkSize + charSize > chunkSize) {
      chunks.push(currentChunk)
      currentChunk = char
      currentChunkSize = charSize
    } else {
      currentChunk += char
      currentChunkSize += charSize
    }
  }

  if (currentChunk) {
    chunks.push(currentChunk)
  }

  return chunks
}
