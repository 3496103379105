import SaveAltIcon from "@mui/icons-material/SaveAlt"
import { Typography } from "@mui/material"
import { useCallback } from "react"
import { trackEvent } from "../../../../utils/mixpanel"
import type { PrintHandle } from "../../../PrintTemplate"
import { ActionMenuItem, IconStyles } from "./ActionsShared.js"

export const SavePDFMenuItem: React.FC<{
  printRef: React.RefObject<PrintHandle | null>
  handleClose: () => void
}> = ({ printRef, handleClose }) => {
  const onClickSave = useCallback(() => {
    trackEvent("CLICK_SAVE_PDF")
    printRef.current?.handlePrintClick()
    handleClose()
  }, [handleClose, printRef])

  return (
    <ActionMenuItem onClick={onClickSave}>
      <SaveAltIcon sx={{ ...IconStyles }} />
      <Typography>Save PDF</Typography>
    </ActionMenuItem>
  )
}
