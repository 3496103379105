import { Cancel } from "@mui/icons-material"
import { ButtonBase, Chip, IconButton, styled, Typography } from "@mui/material"
import { Fragment, useEffect, useMemo, useState } from "react"
import { splitIntoChunks, trackEvent } from "../../utils/mixpanel"
import { extraColors } from "../../utils/theme"
import type { AskHistoryEntry } from "./askDB"
import { AskResult } from "./AskResult"
import { useAskHistory, useIsAsking } from "./useAskApi"

const ASK_CONTAINER_QUERY_WIDTH = 680

const Content = styled("div")(({ theme }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: 24,
  height: "100%",
  overflow: "hidden",
  [theme.containerQueries.up(ASK_CONTAINER_QUERY_WIDTH)]: {
    gridTemplateColumns: "280px 1fr",
  },
}))

const HistoryHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 12,
}))

const ClearChip = styled(Chip)(() => ({
  fontSize: 10,
}))

const HistoryContainer = styled("div")(() => ({
  width: "100%",
  overflow: "auto",
}))

const HistoryEntryButton = styled("div", {
  shouldForwardProp: prop => prop !== "selected",
})<{ selected: boolean }>(({ selected }) => ({
  overflow: "hidden",
  width: "100%",
  display: "flex",
  alignContent: "space-between",
  height: "30px",
  background: selected
    ? `linear-gradient(to bottom, ${extraColors.purpleSubtle}, ${extraColors.subtle})`
    : extraColors.subtle,
  textAlign: "left",
  fontFamily: "inherit",
  ["&:hover"]: {
    background: extraColors.purpleSubtle,
  },
  // show close button on hover
  "&:hover svg": {
    display: "block",
  },
  position: "relative",
}))

const HistoryEntryText = styled(ButtonBase)(({ theme }) => ({
  ...theme.typography.small1,
  padding: "6px 3px 6px 12px",
  display: "inline",
  textAlign: "left",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}))

const CloseButton = styled(IconButton)(() => ({
  right: 0,
  top: 0,
  padding: 4,
  marginLeft: "auto",
  "& svg": {
    display: "none",
    height: 16,
    width: 16,
  },
}))

const ResultContainerQuery = styled("div")(({ theme }) => ({
  position: "relative",
  top: -8,
  [theme.containerQueries.up(ASK_CONTAINER_QUERY_WIDTH)]: {
    display: "none",
  },
}))
const DesktopContainerQuery = styled("div")(({ theme }) => ({
  display: "none",
  [theme.containerQueries.up(ASK_CONTAINER_QUERY_WIDTH)]: {
    display: "block",
    height: "100%",
    overflow: "auto",
  },
}))

export const AskHistory = ({
  pendingAsk,
}: {
  pendingAsk?: AskHistoryEntry
}) => {
  const { askHistory, removeHistoryEntry, clearHistory } = useAskHistory()
  const [selectedHistoryISO, setSelectedHistoryISO] = useState<string>()
  const selectedHistory = useMemo(
    () =>
      (selectedHistoryISO &&
        askHistory.find(h => h.date === selectedHistoryISO)) ||
      pendingAsk ||
      // default to most recent query
      askHistory.at(-1),
    [askHistory, pendingAsk, selectedHistoryISO]
  )
  const sortedByDate = useMemo(() => {
    const entries = pendingAsk
      ? [pendingAsk, ...askHistory.toReversed()]
      : askHistory.toReversed()
    const historyByDayMap = Object.groupBy(entries, a =>
      new Date(a.date).toLocaleDateString()
    )
    return Object.entries(historyByDayMap).sort(
      ([a], [b]) => new Date(b).getTime() - new Date(a).getTime()
    )
  }, [askHistory, pendingAsk])

  // reset selectedHistory when asking
  const isAsking = useIsAsking()
  useEffect(() => {
    if (isAsking) {
      setSelectedHistoryISO(undefined)
    }
  }, [isAsking])

  return (
    <Content>
      <HistoryContainer>
        <HistoryHeader>
          <Typography variant="body2Bold" color={extraColors.medium}>
            History
          </Typography>
          {askHistory.length > 0 && (
            <ClearChip
              label="Clear"
              size="small"
              icon={<Cancel fontSize="small" />}
              clickable
              onClick={() => {
                clearHistory().catch(console.error)
              }}
            />
          )}
        </HistoryHeader>
        {sortedByDate.map(([day, entries]) => (
          <div
            key={day}
            style={{
              display: "grid",
              gap: 6,
              marginBottom: 12,
            }}
          >
            <Typography
              variant="small1"
              color={extraColors.hint}
              display="block"
            >
              {day}
            </Typography>
            {entries?.map(({ date, ragResponse: result }) => (
              <Fragment key={date}>
                <HistoryEntryButton
                  selected={!isAsking && selectedHistory?.date === date}
                  onClick={() => {
                    setSelectedHistoryISO(date)
                    const chunks = splitIntoChunks(
                      result?.rag_context.query ?? ""
                    )
                    trackEvent("CLICK_ASK_HISTORY", { query: chunks })
                  }}
                >
                  <HistoryEntryText>
                    {result?.rag_context.query}
                  </HistoryEntryText>
                  <CloseButton
                    disableRipple
                    onClick={e => {
                      e.stopPropagation()
                      removeHistoryEntry(date).catch(console.error)
                    }}
                  >
                    <Cancel fontSize="small" />
                  </CloseButton>
                </HistoryEntryButton>
                {selectedHistory?.date === date && (
                  <ResultContainerQuery>
                    <AskResult selectedHistory={selectedHistory} />
                  </ResultContainerQuery>
                )}
              </Fragment>
            ))}
          </div>
        ))}
      </HistoryContainer>
      <DesktopContainerQuery>
        <AskResult selectedHistory={selectedHistory} />
      </DesktopContainerQuery>
    </Content>
  )
}
