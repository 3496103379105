import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Button, Menu, styled, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"

import { useSearchParams } from "../../../../hooks/useSearchParams"
import { extraColors } from "../../../../utils/theme"
import type { PrintHandle } from "../../../PrintTemplate"
import { NotificationPreferencesItem } from "./NotificationPreferencesItem"
import { SavePDFMenuItem } from "./SavePDF"
import { ViewMapMenuItem } from "./ViewOnMap"
import { WatchAssetMenuItem } from "./WatchAssetMenuItem"

const ActionsContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "end",
  "@media print": {
    display: "none",
  },
}))

const ActionButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1Bold,
  color: extraColors.navy,
  "&:hover, &:focus, &:active, &:focus-visible": {
    color: extraColors.dark,
  },
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    marginTop: 12,
  },
  [theme.breakpoints.up("sm")]: {
    width: 128,
  },
}))

export const LocationActions: React.FC<{
  className?: string
  printRef: React.RefObject<PrintHandle | null>
  locationId: string | undefined
}> = ({ className, printRef, locationId }) => {
  const { tab } = useSearchParams()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClose = () => setAnchorEl(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const watchAssetToggleItem = <WatchAssetMenuItem />
  const disableSavePDF = tab === "site-details"

  return (
    <ActionsContainer data-testid="user-dropdown" className={className}>
      <ActionButton
        variant="text"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        endIcon={<KeyboardArrowDownIcon />}
        disableRipple={true}
        data-testid="header-user"
      >
        Actions
      </ActionButton>
      <Menu
        id="menu-location-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isMobile ? 60 : -122,
        }}
      >
        {watchAssetToggleItem}
        <NotificationPreferencesItem handleClose={handleClose} />
        <ViewMapMenuItem handleClose={handleClose} locationId={locationId} />
        {!disableSavePDF && (
          <SavePDFMenuItem printRef={printRef} handleClose={handleClose} />
        )}
      </Menu>
    </ActionsContainer>
  )
}
