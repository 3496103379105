import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import * as pmtiles from "pmtiles"
import { MAP_ASSETS_URL } from "../../utils/constants"

export interface PMVectorLayer {
  description: string
  fields: Record<string, unknown>
  id: string
}

export interface PMTileStats {
  layerCount: number
  layers: {
    attributeCount: number
    attributes?: {
      attribute: string
      values?: number[]
      max?: number
      min?: number
      count?: number
    }[]
    layer: string
  }[]
}

export interface PMMetadata {
  name: string
  vector_layers: PMVectorLayer[]
  tilestats: PMTileStats
}

export const allSourceUrl = (accessToken: string) => {
  const source = new pmtiles.FetchSource(
    `${MAP_ASSETS_URL}/all.pmtiles`,
    new Headers({ Authorization: `Bearer ${accessToken}` })
  )

  return new pmtiles.PMTiles(source)
}

const getMapLayerMetadata = async (accessToken: string) => {
  const metadata = (await allSourceUrl(accessToken).getMetadata()) as PMMetadata
  return metadata
}

export const useMapMetadata = () => {
  const auth0 = useAuth0()
  const { getAccessTokenSilently } = auth0
  return useQuery({
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      if (!accessToken) {
        throw new Error("No access token")
      }
      return getMapLayerMetadata(accessToken)
    },

    queryKey: ["metadata"],
    staleTime: Infinity,
    enabled: !!auth0.isAuthenticated,
  })
}

/** When the tiles are built, a description is added that takes an average of
 * some of the biggest features and adds it to the description.
 *
 * This function scales that average area of those large features to a zoom level.
 */
const scaleAreaToMinZoom = (area: number | string) => {
  // Adjust area ranges (in km²)
  const min_area = 0.001 // District level (~1000m²)
  const max_area = 1000 // Country level (~1M km²)

  // Expanded zoom range for 3 levels
  const min_zoom = 1 // World view
  const max_zoom = 13 // Local view

  const log_value = Math.log(Number(area))
  return (
    ((Math.log(max_area) - log_value) /
      (Math.log(max_area) - Math.log(min_area))) *
      (max_zoom - min_zoom) +
    min_zoom
  )
}

/** Description example:
 * "avg_area=0.0513250409046199"
 * */
export const getZoomLevelFromDescription = (description: string) => {
  const area = description.split("=")[1]
  if (!area) {
    console.error("No area found in description", description)
    return 0
  }
  return scaleAreaToMinZoom(area)
}

export const getLayerFromOSMId = (metadata: PMMetadata, osmId: string) => {
  const cleanedId = Math.abs(Number(osmId))

  for (const layer of metadata.tilestats.layers) {
    const hasMatchingId = layer.attributes?.some(
      attr => attr.attribute === "osm_id" && attr.values?.includes(cleanedId)
    )

    if (hasMatchingId) {
      return layer
    }
  }

  return undefined
}

export const getLayerZoomLevels = (
  layer: PMTileStats["layers"][number],
  metadata: PMMetadata,
  maxAdminLevel: number
) => {
  const layerAdminLevel = layer.attributes?.find(
    a => a.attribute === "admin_level"
  )?.max
  const vectorLayer = metadata.vector_layers.find(l => l.id === layer.layer)
  // first 2 characters of layer id are the country code
  const countryCode = layer.layer.slice(0, 2)
  const otherLayers = metadata.vector_layers
    .filter(l => l.id.startsWith(countryCode))
    .sort((a, b) => {
      const aZoom = getZoomLevelFromDescription(a.description)
      const bZoom = getZoomLevelFromDescription(b.description)
      return aZoom - bZoom
    })
  const areas = otherLayers
    .map(l => l.description)
    .map(getZoomLevelFromDescription)

  const isCountry = layer.layer.length === 2

  const areaZoomLevel = getZoomLevelFromDescription(
    vectorLayer?.description ?? "area=0"
  )
  const minzoom = isCountry ? 0 : areaZoomLevel
  const layerIndex = vectorLayer ? otherLayers.indexOf(vectorLayer) : 0
  const maxzoom = areas[layerIndex + 1] || 22

  if (layerAdminLevel === maxAdminLevel) {
    return { minzoom, maxzoom: 22 }
  }
  return { minzoom, maxzoom }
}

// TODO: make map zoom to bounding box of all centroids
// const _getBoundingBoxFromCentroids = (centroids: string[]) => {
//   const coords = centroids.map(point => {
//     const [lng, lat] = point
//       .replace("POINT(", "")
//       .replace(")", "")
//       .split(" ")
//       .map(Number)
//     if (!lng || isNaN(lng) || !lat || isNaN(lat)) {
//       throw new Error(`Invalid centroid: ${point}`)
//     }
//     return { lng, lat }
//   })

//   const bounds = coords.reduce(
//     (acc, coord) => ({
//       west: Math.min(acc.west, coord.lng),
//       south: Math.min(acc.south, coord.lat),
//       east: Math.max(acc.east, coord.lng),
//       north: Math.max(acc.north, coord.lat),
//     }),
//     {
//       west: Infinity,
//       south: Infinity,
//       east: -Infinity,
//       north: -Infinity,
//     }
//   )

//   return [bounds.west, bounds.south, bounds.east, bounds.north] as const
// }
