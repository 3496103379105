import {
  CloseFullscreenRounded,
  CloseRounded,
  OpenInFullRounded,
} from "@mui/icons-material"
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp"
import {
  IconButton,
  InputAdornment,
  Link,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material"
import { memo, useCallback, useMemo, useState } from "react"
import { generatePath, Link as RouterLink } from "react-router"
import { useIsMobile } from "../../hooks/useIsMobile"
import { ROUTES } from "../../utils/constants"
import { splitIntoChunks, trackEvent } from "../../utils/mixpanel"
import { extraColors } from "../../utils/theme"
import type { AskHistoryEntry } from "./askDB"
import { AskFinePrint } from "./AskFinePrint"
import { AskHistory } from "./AskHistory"
import { BetaTag } from "./BetaTag"
import { useAskApi, useNavigatorAuthInterceptor } from "./useAskApi"

export const ASK_ANCHOR_ID = "ask-anchor"

const PopperContainer = styled("div")(() => ({
  padding: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  bottom: 0,
  justifySelf: "center",
  zIndex: 1300,
  width: "100%",
}))

const AskContainer = styled("div", {
  shouldForwardProp: prop => prop !== "isFullScreen",
})<{ isFullScreen: boolean }>(({ isFullScreen, theme }) => ({
  containerType: "inline-size",
  width: "100%",
  maxWidth: 1000,
  height: isFullScreen ? "calc(100vh - 24px)" : "fit-content",
  display: "grid",
  alignContent: "start",
  padding: "18px 24px",
  alignItems: "flex-start",
  gap: "12px",
  borderRadius: "6px",
  background: extraColors.white,
  position: "relative",
  [theme.breakpoints.down("md")]: {
    padding: 12,
  },

  // gradient border
  zIndex: 1,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "inherit",
    padding: "1px", // Adjust this value to control the border width
    background:
      "linear-gradient(270deg, #FFBF34 5.83%, #810099 52.08%, #18345E 94.08%)",
    WebkitMask:
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    WebkitMaskComposite: "xor",
    maskComposite: "exclude",
    zIndex: -1,
  },
}))

const ModalHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
}))

const ModalTitleContainer = styled("div")(() => ({
  display: "flex",
  gap: 6,
  alignItems: "center",
}))

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  gap: 12,
}))

const StyledIconButton = styled(IconButton)(() => ({
  color: extraColors.purpleDark,
  borderRadius: 2,
  border: `1px solid ${extraColors.purpleDark}`,
  width: 32,
  height: 32,
}))

interface AskDialogProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const AskDialogUnMemoed = ({ isOpen, setIsOpen }: AskDialogProps) => {
  useNavigatorAuthInterceptor()
  const [query, setQuery] = useState("")
  const { mutate, isError, error, isPending } = useAskApi()

  const isMobile = useIsMobile()
  const [isFullScreen, setIsFullScreen] = useState(false)

  const anchorEl = useCallback(() => {
    const content = document.querySelector(`#${ASK_ANCHOR_ID}`)
    if (!content) {
      throw new Error("Could not find content ref for ask popper")
    }
    return content
  }, [])

  const onSubmit = useCallback(() => {
    if (!query) {
      return
    }
    setIsFullScreen(true)
    mutate(query)

    const chunks = splitIntoChunks(query)
    trackEvent("QUERY_ASK", { query: chunks })
  }, [mutate, query])

  const pendingQuery: AskHistoryEntry | undefined = useMemo(
    () =>
      isError || isPending
        ? {
            date: new Date().toISOString(),
            ragResponse: {
              rag_context: {
                query,
                answer: error ? `Error: ${error.message || error}` : "",
              },
              data: [],
            },
          }
        : undefined,
    [error, isError, isPending, query]
  )

  return (
    <Modal
      open={isOpen}
      slots={{
        root: PopperContainer,
      }}
      container={isMobile ? undefined : anchorEl}
      hideBackdrop={!isFullScreen}
      onClose={() => setIsOpen(false)}
      disableEnforceFocus
      aria-labelledby="ask-title"
      aria-describedby="ask-description"
    >
      <AskContainer isFullScreen={isFullScreen}>
        <ModalHeader>
          <ModalTitleContainer>
            <Typography variant="h3" display="inline" id="ask-title">
              PHC Navigator
            </Typography>
            <BetaTag />
          </ModalTitleContainer>
          <ButtonContainer>
            <StyledIconButton
              size="small"
              onClick={() => setIsFullScreen(curr => !curr)}
            >
              {isFullScreen ? (
                <CloseFullscreenRounded fontSize="small" />
              ) : (
                <OpenInFullRounded fontSize="small" />
              )}
            </StyledIconButton>
            <StyledIconButton size="small" onClick={() => setIsOpen(false)}>
              <CloseRounded fontSize="small" />
            </StyledIconButton>
          </ButtonContainer>
        </ModalHeader>
        <Typography variant="body2" id="ask-description">
          Ask a question and receive a response based on PHC Global’s
          proprietary knowledge library and access to global health data.{" "}
          <Link
            display="inline"
            variant="body2Bold"
            color={extraColors.purpleMiddle}
            underline="none"
            component={RouterLink}
            // TODO: update to FAQ_POST once it is made
            to={generatePath(ROUTES.FAQ_POST, { slug: "phc-navigator" })}
          >
            How it works
          </Link>
        </Typography>
        <TextField
          id="ask-question"
          placeholder="Ask a public health related question..."
          value={query}
          onChange={e => setQuery(e.target.value)}
          multiline
          onKeyDown={e => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault()
              onSubmit()
            }
          }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end" style={{ alignSelf: "end" }}>
                  <IconButton
                    aria-label={"submit question"}
                    edge="end"
                    onClick={onSubmit}
                  >
                    <ArrowCircleUpIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
            formHelperText: {
              sx: {
                color: extraColors.medium,
              },
            },
          }}
          helperText={!isFullScreen ? <AskFinePrint /> : ""}
        />
        {isFullScreen && <AskHistory pendingAsk={pendingQuery} />}
      </AskContainer>
    </Modal>
  )
}

export const AskDialog = memo(AskDialogUnMemoed)
