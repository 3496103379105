import { Typography } from "@mui/material"
import maplibregl from "maplibre-gl"
import "maplibre-gl/dist/maplibre-gl.css"
import * as pmtiles from "pmtiles"
import { useEffect, useMemo, useState } from "react"
import { GeolocateControl, Map as ReactMapLibre } from "react-map-gl/maplibre"
import { useAccessToken } from "../../hooks/useAccessToken"
import type { TabularData } from "./askDB"
import { AskMapLayers } from "./AskMapLayers"
import { allSourceUrl, useMapMetadata, type PMMetadata } from "./askMapUtils"
import { mapStyle } from "./mapStyle"

export const AskMap = ({ tabularData }: { tabularData?: TabularData }) => {
  const accessToken = useAccessToken()

  // add pmtiles protocol to maplibre with auth
  useEffect(() => {
    if (!accessToken) return
    const protocol = new pmtiles.Protocol({
      metadata: true,
    })
    maplibregl.addProtocol("pmtiles", protocol.tile)
    // add auth for pmtiles in mapStyle
    const source = new pmtiles.FetchSource(
      mapStyle.sources.protomaps.url.replace("pmtiles://", ""),
      new Headers({ Authorization: `Bearer ${accessToken}` })
    )
    const src = new pmtiles.PMTiles(source)
    protocol.add(src)
    const allPmtilesSrc = allSourceUrl(accessToken)
    protocol.add(allPmtilesSrc)
    return () => {
      maplibregl.removeProtocol("pmtiles")
    }
  }, [accessToken])

  const [firstSymbolId, setFirstSymbolId] = useState<string | undefined>()
  const { data: metadata } = useMapMetadata()

  // TODO: use for legend
  const range = useMemo(() => {
    const max =
      tabularData?.data.reduce((acc, d) => Math.max(acc, d.value), 0) ?? 0
    const min =
      tabularData?.data.reduce((acc, d) => Math.min(acc, d.value), Infinity) ??
      0
    return { min, max }
  }, [tabularData?.data])

  const countByOSM = useMemo(() => {
    if (tabularData?.type === "vaccination") {
      // get max value for each location
      const maxByLocation = tabularData.data.reduce<Record<string, number>>(
        (acc, d) => {
          acc[d.location_osm_id] = Math.max(
            acc[d.location_osm_id] ?? 0,
            d.value
          )
          return acc
        },
        {}
      )
      return maxByLocation
    }
    const count = tabularData?.data.reduce(
      (acc, d) => {
        acc[d.location_osm_id] = (acc[d.location_osm_id] ?? 0) + d.value
        return acc
      },
      {} as Record<string, number>
    )
    return count
  }, [tabularData?.data, tabularData?.type])

  if (!tabularData || !metadata || !accessToken) return null
  return (
    <>
      <Typography textTransform="capitalize">{tabularData.type}</Typography>
      <ReactMapLibre
        // add auth for assets
        transformRequest={(url, _sourceType) => {
          return {
            url,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        }}
        failIfMajorPerformanceCaveat
        style={{
          height: "400px",
          width: "100%",
        }}
        id={`${tabularData.type}-${tabularData.diseaseCode}`}
        mapStyle={mapStyle}
        onLoad={e => {
          const map = e.target
          const layers = map.getStyle().layers
          let id
          for (const layer of layers) {
            if (layer.type === "symbol") {
              id = layer.id
              break
            }
          }
          setFirstSymbolId(id)
        }}
        onSourceData={e => {
          if (e.target.getStyle().sources.all && e.isSourceLoaded) {
            setMapFeatureState({
              map: e.target,
              countByOSM,
              metadata,
            })
          }
        }}
        reuseMaps
        onClick={e => {
          const renderedFeatures = e.target.queryRenderedFeatures(e.point)
          renderedFeatures.forEach(f => {
            console.log("clickState", e.target.getFeatureState(f))
          })
          // TODO: remove once popup is implemented
          console.log("renderedFeatures", renderedFeatures)
        }}
      >
        <GeolocateControl position="bottom-left" />

        <AskMapLayers
          firstSymbolId={firstSymbolId}
          range={range}
          countByOSM={countByOSM}
        />
      </ReactMapLibre>
      {/* TODO: Legend */}
    </>
  )
}

export const setMapFeatureState = ({
  map,
  countByOSM,
  metadata,
}: {
  map: maplibregl.Map
  countByOSM?: Record<string, number>
  metadata: PMMetadata
}) => {
  if (!map.getSource("all") || !countByOSM) {
    console.log("missing something", {
      source: map.getSource("all"),
      loaded: map.isSourceLoaded("all"),
      countByOSM,
      sources: map.getStyle().sources,
    })
    return
  }
  metadata.tilestats.layers.forEach(layer => {
    const layerOSMIds = layer.attributes?.find(a => a.attribute === "osm_id")
    layerOSMIds?.values?.forEach(layerOSMId => {
      const id = Math.abs(layerOSMId)
      const count = countByOSM[id * -1]
      if (count) {
        map.setFeatureState(
          {
            source: "all",
            sourceLayer: layer.layer,
            id,
          },
          {
            count,
          }
        )
      }
    })
  })
}
