import { Typography } from "@mui/material"
import { extraColors } from "../../utils/theme"

export const AskFinePrint = () => {
  return (
    <Typography variant="small1" color={extraColors.hint}>
      Navigator is in Beta release for testing. Text is generated using an
      experimental AI architecture. Please check all important information.
    </Typography>
  )
}
