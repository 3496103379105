import { Skeleton, styled, Typography } from "@mui/material"
import React from "react"
import { extraColors } from "../utils/theme"
import Button from "./Shared/Button"

const Card = styled("div")(() => ({
  width: "100%",
  borderRadius: "12px 12px 0px 0px",
}))

const CardHeader = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: "12px 12px 0px 0px",
  height: 80,
  padding: "0px 122px",
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
}))

const ContentHeader = styled("div")(() => ({
  width: "100%",
  height: 64,
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "1fr 1fr 1fr",
  paddingLeft: "15%",
  paddingRight: "15%",
  backgroundColor: extraColors.light,
}))

const ContentBody = styled("div")(() => ({
  width: "100%",
  paddingLeft: "15%",
  paddingRight: "15%",
  paddingTop: 48,
  paddingBottom: 48,
  backgroundColor: extraColors.white,
}))

interface Section {
  title?: string
  subtitle?: string
  action?: {
    label: string
    onClick: (value?: any) => void
  }
  content: React.ReactNode
  testId?: string
}

const ContentCard: React.FC<{
  title: string
  sections: Section[]
}> = ({ title, sections, ...props }) => {
  return (
    <Card {...props}>
      <CardHeader>
        {!title ? (
          <Skeleton width={300} height={36} />
        ) : (
          <Typography
            variant="h2Bold"
            color="textSecondary"
            style={{
              marginRight: "10%",
            }}
          >
            {title}
          </Typography>
        )}
      </CardHeader>
      {sections.map((item, index) => (
        <React.Fragment key={index}>
          {item.title && (
            <ContentHeader>
              <Typography variant="h2Bold" color="textPrimary">
                {item.title}
              </Typography>
              {item.subtitle && (
                <Typography variant="h2Bold">{item.subtitle}</Typography>
              )}
              {item.action && (
                <Button
                  variant="text"
                  style={{ textTransform: "uppercase" }}
                  onClick={() => item.action?.onClick()}
                >
                  {item.action.label}
                </Button>
              )}
            </ContentHeader>
          )}
          <ContentBody>{item.content}</ContentBody>
        </React.Fragment>
      ))}
    </Card>
  )
}

export default ContentCard
