import { styled } from "@mui/material"
import { extraColors } from "../../utils/theme"

export const AskGradientBorder = styled("div")(() => ({
  position: "relative",
  borderRadius: 2,
  background: extraColors.white,
  // gradient border
  zIndex: 1,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "inherit",
    padding: "1px", // Adjust this value to control the border width
    background:
      "linear-gradient(270deg, #18345E 5.83%, #810099 52.08%, #FFBF34 94.08%)",
    WebkitMask:
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    WebkitMaskComposite: "xor",
    maskComposite: "exclude",
    zIndex: -1,
  },
}))
