import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"

/**
 * Hook to keep fresh access token for use in components.
 */
export const useAccessToken = () => {
  const auth0 = useAuth0()
  const [accessToken, setAccessToken] = useState<string | null>(null)
  useEffect(() => {
    const fetchToken = async () => {
      const token = await auth0.getAccessTokenSilently()
      setAccessToken(token)
    }
    fetchToken().catch(console.error)
  }, [auth0])
  return accessToken
}
