import { Box, styled, useMediaQuery, useTheme } from "@mui/material"
import type {
  DataGridProProps,
  GridCallbackDetails,
  GridFilterModel,
  GridRowClassNameParams,
  GridRowSelectionModel,
  GridSortItem,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import {
  DataGridPro,
  gridClasses,
  useGridApiContext,
} from "@mui/x-data-grid-pro"
import clsx from "clsx"
import type React from "react"
import { useCallback, useMemo, useState, type ReactNode } from "react"
import { extraColors } from "../../utils/theme"
import SvgSortDown from "../icons/SortDown"
import SvgSortUp from "../icons/SortUp"
import { GridCustomToolbar } from "./GridCustomToolbar"
import CustomPaginationControls from "./PaginationControls"

const GridContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  overflow: "auto",
  padding: "28px 32px",
  backgroundColor: extraColors.white,
  minHeight: "75vh",
  border: `1px solid ${extraColors.disabled}`,
  borderRadius: 6,
  [theme.breakpoints.down("md")]: {
    padding: "16px",
  },
}))

const Grid = styled(DataGridPro)<{
  "data-hidetoolbar"?: boolean
}>(({ theme, ...props }) => ({
  border: "none",
  "& .MuiDataGrid-main": {
    border: "none",

    "& > div:first-of-type": {
      pointerEvents: "inherit !important",
    },
  },

  "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none",
  },
  "& .MuiDataGrid-row:focus-within": {
    outline: `solid 1px ${theme.palette.primary.main}`,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    ...theme.typography.small1Bold,
    color: extraColors.black,
    letterSpacing: "0.8px",
    textTransform: "uppercase",
    paddingLeft: "10px",
  },
  "& .round-chart-header .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "break-spaces",
    width: "97%",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // This hides the filter adjustment icon above filterable columns.
  // The inputs to the filter menu that this icon exposes aren't very elegant,
  // but if we want to show them to give more granular control, then remove this style.
  "& .MuiDataGrid-filterIcon": {
    display: "none",
  },
  "& .MuiBadge-badge": {
    top: "9px",
    right: "3px",
  },

  "& .MuiDataGrid-menuIcon": {
    marginRight: "5px",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: 0,
  },
  "& .MuiDataGrid-columnHeader": {
    outline: "none",
    padding: "0px",
  },
  "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cellCheckbox:focus-within":
    {
      outline: "none",
    },
  "& .MuiDataGrid-cell, & .MuiDataGrid-row .MuiDataGrid-cell.rowControlCell": {
    border: "none",
  },
  "& .rowControlCell:focus-within": {
    outline: "none",
  },
  ".MuiDataGrid-row:focus-within": {
    outline: "none",
  },
  ".MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    width: "calc(100% - 2px)",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "8px 0",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "1px",
      backgroundColor: extraColors.light,
    },
  },
  ".MuiIconButton-root": {
    color: extraColors.purpleMiddle,
  },
  "& .row-control-remove-button": {
    display: "none",
  },
  "& .MuiDataGrid-row:hover .row-control-remove-button": {
    display: "inherit",
  },
  "& .MuiCheckbox-root svg": {
    color: extraColors.hint,
  },
  "& .Mui-checked svg": {
    color: extraColors.medium,
  },
  "& .MuiDataGrid-selectedRowCount": {
    display: "none",
  },
  "& .centeredCellContent": {
    display: "flex",
  },
  "& .MuiDataGrid-overlay": {
    background: "none",
  },
  "& .MuiDataGrid-toolbarContainer": {
    display: props["data-hidetoolbar"] ? "none" : "inherit",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "transparent",
  },
  [`& .${gridClasses.row}.${gridClasses.row}:hover`]: {
    backgroundColor: extraColors.purpleSubtle,
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: extraColors.subtle,
  },
  [`& .${gridClasses.virtualScrollerRenderZone}`]: {
    width: "100%",
    height: "inherit",
  },
})) as typeof DataGridPro

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}))

interface BaseGridProps<Row extends GridValidRowModel>
  extends DataGridProProps<Row> {
  filter?: boolean
  filterModel?: GridFilterModel
  sort?: boolean
  search?: boolean
  placeholder?: string
  exported?: boolean
  headerActionButton?: React.ReactNode
  footerActionButton?: React.ReactNode
  subNav?: React.ReactNode
  //optionals
  noRowsActionElement?: React.ReactNode
  noResultsActionElement?: React.ReactNode
  initSortModel?: GridSortItem
  noTextOverlay?: string
  loading?: boolean
  "data-hidetoolbar"?: boolean
  checkbox?: boolean
  striped?: boolean
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void
  className?: string
}

export function BaseGrid<Row extends Record<string, any>>({
  rows,
  columns,
  filter = true,
  filterModel,
  placeholder,
  sort,
  search = true,
  exported = false,
  headerActionButton,
  footerActionButton,
  initSortModel,
  noTextOverlay = "No data found",
  noRowsActionElement,
  noResultsActionElement,
  loading,

  checkbox = true,
  columnVisibilityModel = {},
  subNav,
  striped,
  className,
  children,
  getRowClassName,
  slots,
  ...props
}: BaseGridProps<Row> & { children?: ReactNode }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  // TODO: use when server side sorting is implemented
  const [sortModel, setSortModel] = useState<GridSortModel>(
    initSortModel ? [initSortModel] : []
  )
  const handleSortModelChange = useCallback((model: GridSortModel) => {
    setSortModel(model)
  }, [])

  const getRowHeight = useCallback(() => {
    return isMobile ? "auto" : 58
  }, [isMobile])

  const combineRowClassNames = useCallback(
    (params: GridRowClassNameParams<Row>) =>
      clsx(
        params.indexRelativeToCurrentPage % 2 === 0 && striped && isMobile
          ? "even"
          : "odd",
        getRowClassName?.(params)
      ),
    [getRowClassName, isMobile, striped]
  )

  const memoizedSlots: DataGridProProps["slots"] = useMemo(
    () => ({
      pagination: () => (
        <CustomPaginationControls actionButton={footerActionButton} />
      ),
      noResultsOverlay: () => (
        <StyledGridOverlay>
          {!noResultsActionElement ? (
            <Box>
              <NoResultsOverlay />
            </Box>
          ) : (
            noResultsActionElement
          )}
        </StyledGridOverlay>
      ),
      noRowsOverlay: () => (
        <StyledGridOverlay>
          {!noRowsActionElement ? (
            <Box>{noTextOverlay}</Box>
          ) : (
            noRowsActionElement
          )}
        </StyledGridOverlay>
      ),
    }),
    [
      footerActionButton,
      noResultsActionElement,
      noRowsActionElement,
      noTextOverlay,
    ]
  )

  return (
    <GridContainer className={className}>
      <Grid
        scrollbarSize={0}
        filterModel={filterModel}
        localeText={{
          toolbarExportPrint: "Print or Save as PDF",
        }}
        loading={loading}
        columns={columns}
        rows={rows}
        slots={{
          toolbar: GridCustomToolbar,
          columnSortedAscendingIcon: SvgSortDown,
          columnSortedDescendingIcon: SvgSortUp,
          columnUnsortedIcon: null,
          ...memoizedSlots,
          ...slots,
        }}
        slotProps={{
          toolbar:
            filter || sort || search || exported
              ? {
                  placeholder,
                  filter,
                  search,
                  exported,
                  actionButton: headerActionButton,
                  subNav,
                }
              : undefined,
        }}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          ...props.initialState,
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        checkboxSelection={checkbox}
        disableColumnResize
        disableRowSelectionOnClick
        pagination
        disableColumnPinning
        disableColumnSelector
        disableColumnMenu
        getRowHeight={getRowHeight}
        getRowClassName={combineRowClassNames}
        {...props}
      />
      {children}
    </GridContainer>
  )
}

const NoResultsOverlay = () => {
  const dataGridRef = useGridApiContext()
  return (
    <StyledGridOverlay>
      <Box>
        No results for "
        {dataGridRef.current.state.filter.filterModel.quickFilterValues?.join(
          " "
        )}
        "
      </Box>
    </StyledGridOverlay>
  )
}
