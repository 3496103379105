import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined"
import { Link, styled } from "@mui/material"
import { useFlags } from "launchdarkly-react-client-sdk"
import { generatePath, Link as RouterLink } from "react-router"
import usePersistentContext from "../../hooks/usePersistentContext"
import { ROUTES } from "../../utils/constants"
import { extraColors } from "../../utils/theme"
import { FeatureBanner } from "./shared"

const LinkStyled = styled(Link)({
  display: "inline",
  color: extraColors.purpleMiddle,
  textDecoration: "italic",
  marginLeft: 8,
}) as typeof Link

const BannerItemContainer = styled("div")({
  display: "flex",
  alignItems: "center",
})

export const AskNavigatorBanner: React.FC = () => {
  const { showAsk } = useFlags()

  const [
    askNavigatorBannerDismissed,
    setAskNavigatorBannerDismissed,
    isLoading,
  ] = usePersistentContext(["ask-navigator-banner"])

  if (!showAsk || askNavigatorBannerDismissed || isLoading) {
    return null
  }

  return (
    <FeatureBanner
      type="info"
      backgroundColor={"#FFF8e5"}
      borderColor={"#a8a800"}
      open={true}
      dismissFunction={() => void setAskNavigatorBannerDismissed("true")}
    >
      <BannerItemContainer>
        <AutoAwesomeOutlinedIcon
          sx={{
            color: extraColors.purpleMiddle,
            height: "18px",
            marginRight: "2px",
          }}
        />
        Query for real-time biosecurity intelligence through our new tool, PHC
        Navigator!
        <LinkStyled
          variant="body2Bold"
          component={RouterLink}
          to={generatePath(ROUTES.FAQ_POST, { slug: "phc-navigator" })}
        >
          Learn more
        </LinkStyled>
      </BannerItemContainer>
    </FeatureBanner>
  )
}
