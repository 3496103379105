import Dexie, { type EntityTable } from "dexie"

export interface RagDataSource {
  id: string
  source_id: string
  title: string
  subject: string
  description: string
  creator: string
  publisher: string
  url: string
  publish_time: string
  source: string
  rights: string
  location_osm_id: string
  threats: string[]
}

export interface RagResponse {
  data: RagDataSource[]
  rag_context: {
    /** HTML string */
    answer: string
    query: string
  }
}

export interface AskHistoryEntry {
  date: string
  /* @deprecated */
  result?: RagResponse
  ragResponse?: RagResponse
  tabularResponse?: TabularData[]
}

export interface TabularDatum {
  id: number
  source_location: string
  location_osm_id: number
  /** ISO Date */
  start_time: string
  /** ISO Date */
  end_time: string
  value: number
  unit: TableUnit
  type: TableType
  disease_code: string
}

export interface TabularData {
  unit: TableUnit
  type: TableType
  data: TabularDatum[]
  diseaseCode: string
}

// Tabular Fetch Calls
export const TABLE_TYPES = {
  CASES: "cases",
  VACCINATION: "vaccination",
  DEATHS: "deaths",
  HOSPITALIZATIONS: "hospitalizations",
} as const

export type TableType = (typeof TABLE_TYPES)[keyof typeof TABLE_TYPES]

export const TABLE_UNITS = {
  COUNT: "count",
  PERCENT: "percent",
} as const

export type TableUnit = (typeof TABLE_UNITS)[keyof typeof TABLE_UNITS]

/** ----- */

/** IndexedDB for all ask queries */
const askDB = new Dexie("askDB") as Dexie & {
  askHistory: EntityTable<AskHistoryEntry, "date">
}

askDB.version(1).stores({
  askHistory: "date",
})

export { askDB }
